import { Component, OnInit , isDevMode, inject} from '@angular/core';
import { Router, NavigationEnd ,} from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from './icons/icon-subset';
import { Title } from '@angular/platform-browser';

import {Logger} from '../app/shared/service/logger/logger.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  title = 'Sensori.ai ';

  _log = inject(Logger)


  constructor(
    private router: Router,
    private titleService: Title,
    private iconSetService: IconSetService
  ) {
    titleService.setTitle(this.title);
    // iconSet singleton
    iconSetService.icons = { ...iconSubset };
  }

  ngOnInit(): void {

    this._log.info('================================');

    if (isDevMode()) {      
      this._log.info('Enterprise App Development mode!');
    } else {
      this._log.info('Enterprise App Production mode!');
    }
    this._log.info('================================');


    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
  }
}
